export default function Scan() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2253_15083)">
        <path d="M0 6H1L1 1L6 1V0L0 1.07288e-06V6Z" fill="currentColor" />
        <path
          d="M16 10L15 10L15 15L10 15L10 16L16 16L16 10Z"
          fill="currentColor"
        />
        <path
          d="M6 16L6 15L1 15L1 10L-2.62268e-07 10L1.07288e-06 16L6 16Z"
          fill="currentColor"
        />
        <path
          d="M10 -2.62268e-07L10 0.999999L15 1L15 6L16 6L16 0L10 -2.62268e-07Z"
          fill="currentColor"
        />
        <line x1="3" y1="7.5" x2="13" y2="7.5" stroke="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_2253_15083">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
