import React from 'react';

export default function HamburgerButton({ onClick }: { onClick: () => any }) {
  return (
    <svg
      width="17"
      height="8"
      viewBox="0 0 17 8"
      className="cursor-pointer"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <rect x="0.00390625" y="0.5" width="16" height="2" fill="currentColor" />
      <rect x="0.00195312" y="5.5" width="16" height="2" fill="currentColor" />
    </svg>
  );
}
