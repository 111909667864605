import CtaLink from '@/components/CtaLink';
import CtaText from '@/components/CtaText';
import MagnifyingGlass from '@/components/Icons/MagnifyingGlass';
import RightCheveron from '@/components/Icons/RightCheveron';
import UserAvatar from '@/components/Icons/UserAvatar';
import {
  useNavBarTheme,
  useToggleNavBar,
  useToggleSearch,
} from '@/components/PageLayout/PageNavBar/NavBarProvider';
import useUserV2 from '@/hooks/queries/auth_user/useUserV2';
import {
  CLIENT_LOGIN_VIA_EMAIL_URL,
  CLIENT_SIGNUP_VIA_EMAIL_URL,
} from '@/utils/constants';

export default function NavAuthMenuDesktop() {
  const { data: user, isLoading } = useUserV2();

  const toggle = useToggleNavBar();
  const theme = useNavBarTheme();

  const { toggle: toggleSearch } = useToggleSearch();

  return (
    <>
      {user && (
        <div className={`${theme.color}`}>
          <div className="flex items-center">
            <span onClick={toggleSearch} className="mr-3.2 cursor-pointer">
              <MagnifyingGlass />
            </span>
            <div
              onClick={toggle}
              className="flex font-medium uppercase cursor-pointer text-s"
            >
              <span>
                {user?.firstName} {user?.lastName}
              </span>
              <span className="ml-0.8">
                <UserAvatar />
              </span>
            </div>
          </div>
        </div>
      )}
      {(!user || isLoading) && (
        <div className="items-center hidden lgt:flex">
          <span onClick={toggleSearch} className="mr-3.2 cursor-pointer">
            <MagnifyingGlass />
          </span>
          <CtaLink
            href={CLIENT_LOGIN_VIA_EMAIL_URL}
            background="bg-transparent"
            color={`${theme.color} text-opacity-50`}
            margin="mr-2.4"
          >
            <CtaText>LOGIN</CtaText>
          </CtaLink>

          <CtaLink
            href={CLIENT_SIGNUP_VIA_EMAIL_URL}
            background="bg-transparent"
            color={theme.color}
          >
            <CtaText>JOIN</CtaText>
            <RightCheveron />
          </CtaLink>
        </div>
      )}
    </>
  );
}
