import CloseIcon from '@/components/Icons/CloseIcon';
import HamburgerButton from '@/components/Icons/HamburgerButton';
import OleaLogoLink from '@/components/PageLayout/OleaLogoLink';
import NavAuthMenuDesktop from '@/components/PageLayout/PageNavBar/NavAuthMenuDesktop';
import NavBarDesktopLinks from '@/components/PageLayout/PageNavBar/NavBarDesktopLinks';
import {
  NavBarProvider,
  useCloseNavBar,
  useIsNavBarOpen,
  useNavBarTheme,
  useSetScrolling,
  useToggleNavBar,
} from '@/components/PageLayout/PageNavBar/NavBarProvider';
import NavMenuDesktop from '@/components/PageLayout/PageNavBar/NavMenuDesktop';
import NavMenuMobile from '@/components/PageLayout/PageNavBar/NavMenuMobile';
import { NavBarTheme } from '@/components/PageLayout/PageNavBar/types';
import UniversalSearchMenu from '@/components/PageLayout/PageNavBar/UniversalSearchMenu';
import TwelveColGrid from '@/components/TwelveColGrid';
import useIntersectionObserverRef from '@/hooks/useIntersectionObserverRef';
import { CLIENT_INDEX_URL } from '@/utils/constants';
import Image from 'next/legacy/image';
import Link from 'next/link';
import olea_logo from 'public/images/olea_logo.svg';
import { ReactNode } from 'react';

function PageNavBar() {
  const close = useCloseNavBar();
  const toggle = useToggleNavBar();
  const open = useIsNavBarOpen();
  const theme = useNavBarTheme();
  const setScrolling = useSetScrolling();
  const hasScrolledRef = useIntersectionObserverRef(false, () =>
    setScrolling(true)
  );
  const atTopRef = useIntersectionObserverRef(false, () => setScrolling(false));

  return (
    <>
      <div className="absolute" ref={atTopRef} />
      <div className={theme.sticky}>
        {/* MOBILE */}
        <div
          className={`ease-in z-40 relative flex items-center justify-between lgt:hidden mt-1.2 lgt:mt-3.2 h-4.8 w-[calc(100%-1.6rem)] mx-0.8 px-0.8 ${theme.color} ${theme.background} ${theme.backgroundOpacity} ${theme.shadow}`}
        >
          <OleaLogoLink height={16} priority addClassStyles={theme.filter} />
          <HamburgerButton onClick={toggle} />
        </div>
        {open && <NavMenuMobile />}

        {/* DESKTOP */}
        <div
          onMouseLeave={close}
          className={`z-40 relative hidden items-center justify-between lgt:flex mt-0.8 h-5.6 w-full max-w-[125.6rem] px-6.4 mx-auto ${theme.color} ${theme.background} ${theme.backgroundOpacity} ${theme.shadow}`}
        >
          <div className="flex items-center">
            <OleaLogoLink height={22} priority addClassStyles={theme.filter} />
            <NavBarDesktopLinks />
          </div>
          <NavAuthMenuDesktop />
          {open && <NavMenuDesktop />}
        </div>

        <UniversalSearchMenu />
      </div>
      <div className="absolute top-[105%]" ref={hasScrolledRef} />
    </>
  );
}

export function NavBarDarkTheme({ sticky = true }: { sticky?: boolean }) {
  return (
    <NavBarProvider sticky={sticky} theme={NavBarTheme.DARK}>
      <PageNavBar />
    </NavBarProvider>
  );
}

export function NavBarLightTheme({ sticky = true }: { sticky?: boolean }) {
  return (
    <NavBarProvider sticky={sticky} theme={NavBarTheme.LIGHT}>
      <PageNavBar />
    </NavBarProvider>
  );
}
export function EmptyLinkNavBar({
  children,
  href,
  closeCopy = 'CLOSE',
}: {
  children?: ReactNode;
  href: string;
  closeCopy?: string;
}) {
  return (
    <TwelveColGrid my="pt-[1.2rem] lgt:pt-1.6">
      <div className="col-span-12 h-4.8 lgt:h-5.6 flex justify-between items-center relative">
        <Link href={CLIENT_INDEX_URL}>
          <Image
            height={40}
            priority
            alt="OLEA Logo"
            layout="intrinsic"
            src={olea_logo}
            className="filter dark:invert"
          />
        </Link>
        {children}
        <Link href={href} className="flex items-center w-[6.8rem] justify-end">
          <div className="font-medium text-s">{closeCopy}</div>
          <CloseIcon fill="currentColor" large />
        </Link>
      </div>
    </TwelveColGrid>
  );
}

export function EmptyCallbackNavBar({
  children,
  cbFn,
  closeCopy = 'CLOSE',
}: {
  children?: ReactNode;
  cbFn: () => any;
  closeCopy?: string;
}) {
  return (
    <TwelveColGrid my="pt-[1.2rem] lgt:pt-1.6">
      <div className="col-span-12 h-4.8 lgt:h-5.6 flex justify-between items-center relative">
        <Link href={CLIENT_INDEX_URL}>
          <Image
            height={40}
            priority
            alt="OLEA Logo"
            layout="intrinsic"
            src={olea_logo}
            className="filter dark:invert"
          />
        </Link>
        {children}
        <button
          onClick={cbFn}
          className="flex items-center w-[6.8rem] justify-end"
        >
          <div className="font-medium text-s">{closeCopy}</div>
          <CloseIcon fill="currentColor" large />
        </button>
      </div>
    </TwelveColGrid>
  );
}
