import { CLIENT_INDEX_URL } from '@/utils/constants';
import Image from "next/legacy/image";
import Link from 'next/link';
import olea_logo from 'public/images/olea_logo.svg';

export default function OleaLogoLink({
  height,
  priority = false,
  addClassStyles = '',
}: {
  height: number;
  priority: boolean;
  addClassStyles?: string;
}) {
  return (
    (<Link href={CLIENT_INDEX_URL} style={{ height }} className={addClassStyles}>

      <Image
        height={height}
        priority={priority}
        alt="OLEA Logo"
        layout="intrinsic"
        src={olea_logo}
      />

    </Link>)
  );
}
