import { Artist, Show, Variant } from '@/lib/interfaces';
import { getArtistImage, getVariantImage } from '@/utils/v2HelperMethods';
import Image from 'next/image';
import Link from 'next/link';
import { ReactNode } from 'react';
import AttributeBox from '../AttributeBox';
import RightCheveron from '../Icons/RightCheveron';

type SmallCardProps = {
  href: string;
  width: string;
  height: string;
  py?: string;
  px?: string;
  mx?: string;
  my?: string;
  border?: string;
  children?: ReactNode;
  hasCheveron?: boolean;
};

export default function SmallCard({
  href,
  width,
  height,
  py,
  px,
  mx,
  my,
  border,
  hasCheveron = true,
  children,
}: SmallCardProps) {
  return (
    <Link
      href={href}
      className={`relative z-10 flex justify-between items-center group ${width} ${height} ${py} ${px} ${mx} ${my} ${border}`}
    >
      {children}
      {hasCheveron && <RightCheveron />}
    </Link>
  );
}

export function SmallVariantCard({
  variant,
  ...props
}: SmallCardProps & {
  variant: Variant;
}) {
  return (
    <SmallCard {...props}>
      <div className="h-4.8 w-4.8 relative flex-shrink-0">
        <Image
          fill
          src={getVariantImage(variant)}
          alt=""
          sizes="10vw"
          className="object-contain filter drop-shadow-[0_4px_10px_rgba(0,0,0,0.3)]"
        />
      </div>
      <div className="ml-1.6 flex items-center justify-between w-full">
        <div className="uppercase text-s">
          <div className="font-medium">{variant.name}</div>
          <div className="flex mt-0.8">
            {[variant.medium]
              .filter((v) => !!v)
              .map((v) => (
                <AttributeBox key={v}>{v}</AttributeBox>
              ))}
          </div>
        </div>
      </div>
    </SmallCard>
  );
}

export function SmallArtistCard({
  artist,
  ...props
}: SmallCardProps & {
  artist: Artist;
}) {
  return (
    <SmallCard {...props}>
      <div className="h-4.8 w-4.8 relative flex-shrink-0">
        <Image
          fill
          src={getArtistImage(artist)}
          alt=""
          sizes="10vw"
          className="object-cover"
        />
      </div>
      <div className="ml-1.6 flex items-center justify-between w-full">
        <span className="font-medium uppercase text-s">{artist.name}</span>
      </div>
    </SmallCard>
  );
}
export function SmallShowCard({
  show,
  ...props
}: SmallCardProps & {
  show: Show;
}) {
  return (
    <SmallCard {...props}>
      <div className="h-4.8 w-4.8 relative flex-shrink-0">
        <Image
          fill
          src={getVariantImage(show.variants[0])}
          alt=""
          sizes="10vw"
          className="object-contain"
        />
      </div>
      <div className="ml-1.6 flex items-center justify-between w-full">
        <span className="font-medium uppercase text-s">{show.name}</span>
      </div>
    </SmallCard>
  );
}
