import { useContext, useMemo, useState } from 'react';
import { NavBarContext, NavBarThemeStyles } from './NavBarContext';
import { NavBarTheme } from '@/components/PageLayout/PageNavBar/types';

export function useIsNavSticky() {
  const ctx = useContext(NavBarContext);
  if (!ctx) throw Error('Need to Set Context Value');
  const { sticky } = ctx;
  return sticky;
}

export function useToggleSearch() {
  const ctx = useContext(NavBarContext);
  if (!ctx) throw Error('Need to Set Context Value');
  const { setIsSearchOpen } = ctx;
  return {
    toggle: () => setIsSearchOpen((prev: boolean) => !prev),
  };
}

export function useIsSearchOpen() {
  const ctx = useContext(NavBarContext);
  if (!ctx) throw Error('Need to Set Context Value');
  const { isSearchOpen } = ctx;
  return isSearchOpen;
}

export function useToggleNavBar() {
  const ctx = useContext(NavBarContext);
  if (!ctx) throw Error('Need to Set Context Value');
  const { setOpen } = ctx;
  return () => setOpen((prev: boolean) => !prev);
}

export function useCloseNavBar() {
  const ctx = useContext(NavBarContext);
  if (!ctx) throw Error('Need to Set Context Value');
  const { setOpen } = ctx;
  return () => setOpen(false);
}

export function useIsNavBarOpen() {
  const ctx = useContext(NavBarContext);
  if (!ctx) throw Error('Need to Set Context Value');
  const { open } = ctx;
  return open;
}

export function useNavBarTheme() {
  const ctx = useContext(NavBarContext);
  if (!ctx) throw Error('Need to Set Context Value');
  const { theme } = ctx;
  return theme;
}

export function useSetScrolling() {
  const ctx = useContext(NavBarContext);
  if (!ctx) throw Error('Need to Set Context Value');
  const { setScrolling } = ctx;
  return setScrolling;
}

export function NavBarProvider({
  sticky,
  theme,
  children,
}: {
  theme: NavBarTheme;
  sticky: boolean;
  children: React.ReactNode;
}) {
  const [open, setOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [scrolling, setScrolling] = useState(false);

  const themeObject: NavBarThemeStyles = (() => {
    switch (theme) {
      case NavBarTheme.DARK:
        return {
          color: 'text-white',
          textHover: 'hover:text-opacity-50',
          transition: 'transition-all ease-in duration-150',
          background: 'bg-black',
          backgroundOpacity: 'bg-opacity-100',
          border: 'border-white border-opacity-50',
          shadow: scrolling ? 'shadow-navbar' : '',
          filter: 'filter invert',
          sticky: sticky ? 'sticky top-1.2 lgt:top-0.8 z-20' : '',
        };
      case NavBarTheme.LIGHT:
        return {
          color: 'text-black',
          textHover: 'hover:text-opacity-50',
          transition: 'transition-all ease-in duration-150',
          background: 'bg-white',
          backgroundOpacity: 'bg-opacity-100',
          border: 'border-black border-opacity-50',
          shadow: scrolling ? 'shadow-navbar' : '',
          filter: '',
          sticky: sticky ? 'sticky top-1.2 lgt:top-0.8 z-20' : '',
        };
    }
  })();

  const navContext = useMemo(
    () => ({
      open,
      setOpen,
      theme: themeObject,
      sticky,
      isSearchOpen,
      setIsSearchOpen,
      scrolling,
      setScrolling,
    }),
    [open, sticky, scrolling, isSearchOpen, themeObject]
  );

  return (
    <NavBarContext.Provider value={navContext}>
      {children}
    </NavBarContext.Provider>
  );
}
