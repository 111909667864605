import CtaLink, { SecondaryCtaLink } from '@/components/CtaLink';
import CtaText from '@/components/CtaText';
import CloseIcon from '@/components/Icons/CloseIcon';
import MagnifyingGlass from '@/components/Icons/MagnifyingGlass';
import Scan from '@/components/Icons/Scan';
import UserAvatar from '@/components/Icons/UserAvatar';
import OleaLogoLink from '@/components/PageLayout/OleaLogoLink';
import {
  useToggleNavBar,
  useToggleSearch,
} from '@/components/PageLayout/PageNavBar/NavBarProvider';
import { NavMenuLink } from '@/components/PageLayout/PageNavBar/NavMenuLink';
import useUserV2 from '@/hooks/queries/auth_user/useUserV2';
import { logout } from '@/utils/http/userAuthRequests';
import {
  CLIENT_ABOUT_URL,
  CLIENT_ADMIN_TRENDING_VIEW_URL,
  CLIENT_ADMIN_WAITLIST_VIEW_URL,
  CLIENT_ARTIST_COLLECTORS_URL,
  CLIENT_ARTIST_WAITLIST_PAGE_URL,
  CLIENT_DISCOVER_URL,
  CLIENT_GALERY_WAITLIST_PAGE_URL,
  CLIENT_INDEX_URL,
  CLIENT_LOGIN_VIA_EMAIL_URL,
  CLIENT_OLEA_COLLECTION_URL,
  CLIENT_SCAN_ARTWORK_URL,
  CLIENT_SIGNUP_VIA_EMAIL_URL,
  CLIENT_USER_ACCOUNT_URL,
  CLIENT_USER_COLLECTION_URL,
} from '@/utils/constants';
import {
  isAdmin,
  isArtist,
  isAuthenticated,
  isGallery,
} from '@/utils/v2HelperMethods';
import Link from 'next/link';
import { useEffect } from 'react';

export default function NavMenuMobile() {
  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    if (window.innerWidth < 1129) document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, []);
  const { data: user, isLoading } = useUserV2();

  const artist = !isLoading && isArtist(user);
  const admin = !isLoading && isAdmin(user);
  const gallery = !isLoading && isGallery(user);
  const auth = !artist && isAuthenticated(user);

  const toggle = useToggleNavBar();

  return (
    <>
      <div className="fixed top-0 left-0 z-40 w-screen h-screen text-black bg-white lgt:hidden">
        <div className="w-full px-1.6 flex justify-between items-center mt-1.2 h-4.8">
          <OleaLogoLink height={16} priority={false} />
          <CloseIcon onClick={toggle} large />
        </div>

        <div className="flex flex-col justify-between h-[calc(100%-4.8rem)] col-span-12 py-1.6">
          <div className="flex flex-col bg-gray-accent-2 px-1.6">
            {!gallery && !artist && (
              <>
                <SearchRow />

                <NavMenuLink
                  href={CLIENT_SCAN_ARTWORK_URL}
                  copy={'SCAN AN ARTWORK'}
                  icon={<Scan />}
                />
                <NavMenuLink
                  href={CLIENT_USER_COLLECTION_URL}
                  copy={'COLLECTION MANAGEMENT'}
                />
                <NavMenuLink href={CLIENT_DISCOVER_URL} copy={'DISCOVER'} />
              </>
            )}
            {user && (
              <NavMenuLink
                href={CLIENT_OLEA_COLLECTION_URL}
                copy={'OLEA COLLECTION'}
              />
            )}
            <NavMenuLink href={CLIENT_ABOUT_URL} copy={'ABOUT'} border="" />
            <div className="relative z-10 border-b border-gray-accent-2"></div>
          </div>

          <div className="flex flex-col mb-5.6 bg-gray-accent-2 px-1.6">
            {user && (
              <div className="flex items-center justify-between font-medium text-4xlt py-1.6 border-b border-black border-opacity-10">
                <span>
                  {artist && (
                    <span className="block text-caption mb-0.8 text-black text-opacity-50">
                      ARTIST PROFILE
                    </span>
                  )}
                  {gallery && (
                    <span className="block text-caption mb-0.8 text-black text-opacity-50">
                      GALLERY PROFILE
                    </span>
                  )}
                  <span className="copy-l">
                    {user?.firstName} {user?.lastName}
                  </span>
                </span>
                <UserAvatar />
              </div>
            )}
            {auth && (
              <>
                <NavMenuLink
                  href={CLIENT_USER_COLLECTION_URL}
                  copy={'MY COLLECTION'}
                />
                <NavMenuLink
                  href={CLIENT_USER_ACCOUNT_URL}
                  copy={'ACCOUNT DETAILS'}
                />
              </>
            )}
            {artist && (
              <>
                <NavMenuLink
                  href={CLIENT_ARTIST_COLLECTORS_URL}
                  copy={'MY COLLECTORS'}
                />
                <NavMenuLink
                  href={CLIENT_ARTIST_WAITLIST_PAGE_URL}
                  copy={'MY WAITLIST'}
                />
                <NavMenuLink
                  href={CLIENT_USER_ACCOUNT_URL}
                  copy={'ACCOUNT DETAILS'}
                />
              </>
            )}
            {gallery && (
              <>
                <NavMenuLink
                  href={CLIENT_GALERY_WAITLIST_PAGE_URL}
                  copy={'MY WAITLIST'}
                />
                <NavMenuLink
                  href={CLIENT_USER_ACCOUNT_URL}
                  copy={'ACCOUNT DETAILS'}
                />
              </>
            )}
            {admin && (
              <>
                <NavMenuLink
                  href={'/user/admin/users'}
                  copy={'USER PROFILES'}
                />
                <NavMenuLink
                  href={CLIENT_ADMIN_WAITLIST_VIEW_URL('kai')}
                  copy={'WAITLIST VIEWER'}
                />
                <NavMenuLink
                  href={CLIENT_ADMIN_TRENDING_VIEW_URL}
                  copy={'TRENDING'}
                />
              </>
            )}
            {user && (
              <Link
                href={CLIENT_INDEX_URL}
                onClick={logout}
                className="flex items-center justify-between py-1.6 text-s font-medium text-black-300"
              >
                LOG OUT
              </Link>
            )}
          </div>
          {!user && (
            <div className="flex flex-col mb-60">
              <SecondaryCtaLink
                border="border border-black"
                margin="mx-auto mb-0.8"
                href={CLIENT_SIGNUP_VIA_EMAIL_URL}
              >
                <CtaText>JOIN</CtaText>
              </SecondaryCtaLink>
              <CtaLink margin="mx-auto" href={CLIENT_LOGIN_VIA_EMAIL_URL}>
                <CtaText>LOGIN</CtaText>
              </CtaLink>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

function SearchRow() {
  const { toggle } = useToggleSearch();

  return (
    <div
      onClick={toggle}
      className="flex items-center justify-between font-medium text-s py-1.6 border-b border-black border-opacity-10"
    >
      <span>SEARCH</span>
      <MagnifyingGlass />
    </div>
  );
}
