import CtaButton from '@/components/CtaButton';
import CtaText from '@/components/CtaText';
import CloseIcon from '@/components/Icons/CloseIcon';
import LoadingSpinner from '@/components/Icons/LoadingSpinner';
import OleaLogoLink from '@/components/PageLayout/OleaLogoLink';
import {
  useIsSearchOpen,
  useToggleSearch,
} from '@/components/PageLayout/PageNavBar/NavBarProvider';
import SearchSortAndFilterProviderV2, {
  useSearchSortAndFilterProviderV2,
} from '@/components/Provider/SearchSortAndFilterProviderV2';
import TabProvider, { useTabProvider } from '@/components/Provider/TabProvider';

import ScreenOverlay from '@/components/ScreenOverlay';
import NoResultsFound from '@/components/SearchBar/NoResultsFound';
import {
  SmallArtistCard,
  SmallShowCard,
  SmallVariantCard,
} from '@/components/SmallCard';
import TwelveColGrid from '@/components/TwelveColGrid';
import useIntersectionObserverRef from '@/hooks/useIntersectionObserverRef';

import {
  ApiResponse,
  Artist,
  MultiSearchResponse,
  PopulationOptionV2,
  Show,
  SortOptionV2,
  Variant,
} from '@/lib/interfaces';
import {
  CLIENT_ARTIST_PROFILE_URL,
  CLIENT_ARTWORKS_URL,
  CLIENT_SHOWS_BY_NAME_URL,
} from '@/utils/constants';
import { pub_multiSearch } from '@/utils/v2HttpRequests';
import { useEffect } from 'react';

export default function UniversalSearchMenu() {
  const isSearchOpen = useIsSearchOpen();
  const { toggle } = useToggleSearch();

  const initialSortOption: SortOptionV2 = { title: 'id:desc', sort: 'id:DESC' };
  const initialPopulations: PopulationOptionV2[] = [
    { populate: { image: true } },
  ];

  return (
    <ScreenOverlay
      open={isSearchOpen}
      cbFunc={toggle}
      display="flex flex-col items-center"
      background="bg-black bg-opacity-10 backdrop-blur-[4px]"
      zIndex="z-50"
    >
      <TabProvider initialTab={-1}>
        <SearchSortAndFilterProviderV2
          fetchFn={pub_multiSearch}
          initialPopulations={initialPopulations}
          initialSortOption={initialSortOption}
        >
          <SearchDrawer />
        </SearchSortAndFilterProviderV2>
      </TabProvider>
    </ScreenOverlay>
  );
}

function SearchDrawer() {
  const { toggle } = useToggleSearch();
  const { searchText } = useSearchSortAndFilterProviderV2();

  useEffect(() => {
    document.body.style.position = 'fixed';
    document.body.style.overflowY = 'scroll';
    document.body.style.width = '100%';

    return () => {
      document.body.style.position = 'initial';
      document.body.style.overflowY = 'initial';
      document.body.style.width = 'initial';
    };
  }, []);

  const bgColor = searchText?.length
    ? 'bg-white lgt:bg-white'
    : 'bg-white lgt:bg-transparent';

  return (
    <div
      onClick={(e) => searchText?.length && e.stopPropagation()}
      className={`w-full h-full ${bgColor}`}
    >
      <div className="w-full px-1.6 flex justify-between items-center mt-1.2 h-4.8 lgt:hidden">
        <OleaLogoLink height={16} priority={false} />
        <CloseIcon onClick={toggle} large />
      </div>
      <SearchBar />
      {searchText?.length > 0 && (
        <>
          <ButtonRow />
          <ResultsGrid />
        </>
      )}
    </div>
  );
}

function SearchBar() {
  const { searchText, closeSearch, updateSearchText } =
    useSearchSortAndFilterProviderV2();
  const { toggle } = useToggleSearch();

  const closeMenu = () => {
    toggle();
    closeSearch();
  };

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="w-full max-w-[125.6rem] px-1.6 lgt:px-6.4 lgt:mt-0.8 mx-auto  bg-white h-3.2 lgt:h-5.6 lgt:shadow-navbar"
    >
      <div className="relative flex items-center h-full border-b border-grey-200 lgt:border-none">
        <input
          type={'text'}
          className="h-3.2 pb-0.8 lgt:pb-0 w-full outline-none text-s placeholder:text-black-300 text-black-100"
          placeholder="SEARCH BY ARTWORK, ARTIST OR SHOW..."
          value={searchText}
          onChange={(e) => updateSearchText(e.target.value)}
        />

        {searchText.length ? (
          <div className="flex">
            <span
              onClick={() => updateSearchText('')}
              className="text-s text-black-300 lgt:mr-1.6"
            >
              CLEAR
            </span>
            <div className="hidden lgt:block">
              <CloseIcon onClick={closeMenu} />
            </div>
          </div>
        ) : (
          <div className="flex">
            <span
              onClick={closeMenu}
              className="text-s text-black-300 lgt:mr-1.6 cursor-pointer"
            >
              CANCEL
            </span>
            <div className="hidden lgt:block">
              <CloseIcon onClick={closeMenu} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function ButtonRow() {
  const { tab, setTab } = useTabProvider();
  const { ssfQuery } = useSearchSortAndFilterProviderV2();

  const borderColor = (num: number) => {
    if (tab === -1) return 'border border-grey-300';
    if (tab === num) return 'border border-black-100';
    return 'border border-grey-300';
  };

  return (
    <div className="flex mt-2.4 lgt:mt-4.8 overflow-x-scroll noscrollbar mx-auto max-w-screen-lgt px-1.6 lgt:px-0">
      <CtaButton
        onClick={() => setTab((prev) => (prev === 0 ? -1 : 0))}
        background="bg-white"
        border={borderColor(0)}
        color="text-black-100"
        width=""
        height=""
        margin="mr-0.8"
        padding="py-1.6 px-3.2"
        disabled={!ssfQuery.isSuccess}
      >
        <CtaText font="text-xs font-normal">ARTWORKS</CtaText>
        <span className="inline text-xs font-normal">
          {ssfQuery.isSuccess ? (
            `(${ssfQuery.data.pages[0].data.variants.pagination.total})`
          ) : (
            <LoadingSpinner />
          )}
        </span>
      </CtaButton>
      <CtaButton
        onClick={() => setTab((prev) => (prev === 1 ? -1 : 1))}
        background="bg-white"
        border={borderColor(1)}
        color="text-black-100"
        width=""
        height=""
        margin="mr-0.8"
        padding="py-1.6 px-3.2"
        disabled={!ssfQuery.isSuccess}
      >
        <CtaText font="text-xs font-normal">ARTISTS </CtaText>
        <span className="inline text-xs font-normal">
          {ssfQuery.isSuccess ? (
            `(${ssfQuery.data.pages[0].data.artists.pagination.total})`
          ) : (
            <LoadingSpinner />
          )}
        </span>
      </CtaButton>
      <CtaButton
        onClick={() => setTab((prev) => (prev === 2 ? -1 : 2))}
        background="bg-white"
        border={borderColor(2)}
        color="text-black-100"
        width=""
        height=""
        padding="py-1.6 px-3.2"
        disabled={!ssfQuery.isSuccess}
      >
        <CtaText font="text-xs font-normal">SHOWS </CtaText>
        <span className="inline text-xs font-normal">
          {ssfQuery.isSuccess ? (
            `(${ssfQuery.data.pages[0].data.shows.pagination.total})`
          ) : (
            <LoadingSpinner />
          )}
        </span>
      </CtaButton>
    </div>
  );
}

function ResultsGrid() {
  const { ssfQuery } = useSearchSortAndFilterProviderV2();
  const { tab } = useTabProvider();

  const lastElementRef = useIntersectionObserverRef(
    ssfQuery.isLoading || !ssfQuery.hasNextPage || ssfQuery.isFetchingNextPage,
    ssfQuery.fetchNextPage
  );

  const variantsTabActive = tab === 0 || tab === -1;
  const artistTabActive = tab === 1 || tab === -1;
  const showsTabActive = tab === 2 || tab === -1;
  if (ssfQuery.isLoading)
    return (
      <TwelveColGrid addClassStyles="relative" px="" gap="gap-0">
        <div className="flex justify-center col-span-12 border-black my-96">
          <LoadingSpinner currentColor="text-black-100" size="w-24 h-24" />
        </div>
      </TwelveColGrid>
    );
  if (ssfQuery.isSuccess) {
    const showsPageCount =
      ssfQuery.data.pages[0].data.shows.pagination.pageCount;
    const variantsPageCount =
      ssfQuery.data.pages[0].data.variants.pagination.pageCount;
    const artistsPageCount =
      ssfQuery.data.pages[0].data.artists.pagination.pageCount;
    const currentPage =
      ssfQuery.data.pageParams[ssfQuery.data.pageParams.length - 1] || -1;
    const showsTabCanFetchMore = showsTabActive && showsPageCount > currentPage;
    const variantsTabCanFetchMore =
      variantsTabActive && variantsPageCount > currentPage;
    const artistsTabCanFetchMore =
      artistTabActive && artistsPageCount > currentPage;

    return (
      <div className="h-[48rem] overflow-y-scroll noscrollbar mt-2.4 lgt:mt-4.8 px-1.6 lgt:px-0 mx-auto max-w-screen-lgt">
        <TwelveColGrid addClassStyles="relative" px="" gap="gap-0">
          {ssfQuery.data.pages[0].meta.pagination.total === 0 && (
            <NoResultsFound />
          )}

          {showsTabActive &&
            ssfQuery.data.pages.map((page: ApiResponse<MultiSearchResponse>) =>
              page.data.shows.results.map((s: Show) => (
                <div
                  key={s.id}
                  className="flex items-center col-span-12 bg-white lgt:col-span-4 py-0.8 px-1.2 border-grey-200 border-b lgt:border-r self-start"
                >
                  <SmallShowCard
                    show={s}
                    href={CLIENT_SHOWS_BY_NAME_URL(s.name)}
                    height={'h-6.4'}
                    width={'col-span-12 lgt:col-span-4 w-full'}
                  />
                </div>
              ))
            )}

          {artistTabActive &&
            ssfQuery.data.pages.map((page: ApiResponse<MultiSearchResponse>) =>
              page.data.artists.results.map((a: Artist) => (
                <div
                  key={a.id}
                  className="flex items-center col-span-12 bg-white lgt:col-span-4 py-0.8 px-1.2 border-grey-200 border-b lgt:border-r self-start"
                >
                  <SmallArtistCard
                    artist={a}
                    href={CLIENT_ARTIST_PROFILE_URL(a.url_handle)}
                    height={'h-6.4'}
                    width={'w-full'}
                  />
                </div>
              ))
            )}

          {variantsTabActive &&
            ssfQuery.data.pages.map((page: ApiResponse<MultiSearchResponse>) =>
              page.data.variants.results.map((v: Variant) => (
                <div
                  key={v.id}
                  className=" flex items-center col-span-12 bg-white lgt:col-span-4 py-0.8 px-1.2 border-grey-200 border-b lgt:border-r self-start"
                >
                  <SmallVariantCard
                    key={v.id}
                    href={CLIENT_ARTWORKS_URL + '/' + v.id}
                    variant={v}
                    height={'h-6.4'}
                    width={' w-full'}
                  />
                </div>
              ))
            )}

          {ssfQuery.isFetchingNextPage && (
            <div className="flex justify-center col-span-12 border-black my-96">
              <LoadingSpinner currentColor="text-black-100" size="w-24 h-24" />
            </div>
          )}
          {(showsTabCanFetchMore ||
            variantsTabCanFetchMore ||
            artistsTabCanFetchMore) && (
            <div className="opacity-0" ref={lastElementRef}></div>
          )}
          <div className="absolute top-0 left-0 w-full h-full border-2 border-white"></div>
        </TwelveColGrid>
      </div>
    );
  }
  return <></>;
}
