import { useNavBarTheme } from '@/components/PageLayout/PageNavBar/NavBarProvider';
import { NavMenuLink } from '@/components/PageLayout/PageNavBar/NavMenuLink';
import useUserV2 from '@/hooks/queries/auth_user/useUserV2';
import { logout } from '@/utils/http/userAuthRequests';
import {
  CLIENT_ADMIN_TRENDING_VIEW_URL,
  CLIENT_ADMIN_WAITLIST_VIEW_URL,
  CLIENT_ARTIST_COLLECTORS_URL,
  CLIENT_ARTIST_WAITLIST_PAGE_URL,
  CLIENT_GALERY_WAITLIST_PAGE_URL,
  CLIENT_USER_ACCOUNT_URL,
  CLIENT_USER_COLLECTION_URL,
} from '@/utils/constants';
import {
  isAdmin,
  isArtist,
  isAuthenticated,
  isGallery,
} from '@/utils/v2HelperMethods';

const ProfileIdentifier = ({ children }: { children: React.ReactNode }) => {
  const theme = useNavBarTheme();
  return (
    <span
      className={`block -mb-0.8 ${theme.color} text-opacity-50 text-caption uppercase`}
    >
      {children}
    </span>
  );
};

export default function NavMenuDesktop() {
  const { data: user, isLoading } = useUserV2();

  const artist = !isLoading && isArtist(user);
  const admin = !isLoading && isAdmin(user);
  const gallery = !isLoading && isGallery(user);
  const auth = !isLoading && isAuthenticated(user);
  const theme = useNavBarTheme();

  return (
    <div className="absolute z-50 top-full w-full max-w-[125.6rem] left-1/2 -translate-x-1/2 flex justify-end">
      <div
        className={`${theme.color} ${theme.background} ${theme.backgroundOpacity} px-1.6 w-26.4 relative z-40 shadow-navbar`}
      >
        {admin && (
          <>
            <NavMenuLink
              border={`border-b ${theme.border}`}
              href={'/user/admin/users'}
              copy={'USER PROFILES'}
            />
            <NavMenuLink
              border={`border-b ${theme.border}`}
              href={CLIENT_ADMIN_WAITLIST_VIEW_URL('kai')}
              copy={'WAITLIST VIEWER'}
            />
            <NavMenuLink
              border={`border-b ${theme.border}`}
              href={CLIENT_ADMIN_TRENDING_VIEW_URL}
              copy={'TRENDING'}
            />
          </>
        )}
        {auth && (
          <>
            <NavMenuLink
              border={`border-b ${theme.border}`}
              href={CLIENT_USER_COLLECTION_URL}
              copy={'MY COLLECTION'}
            />
            <NavMenuLink
              border={`border-b ${theme.border}`}
              href={CLIENT_USER_ACCOUNT_URL}
              copy={'ACCOUNT DETAILS'}
            />
          </>
        )}
        {artist && (
          <>
            <ProfileIdentifier>ARTIST PROFILE</ProfileIdentifier>
            <NavMenuLink
              border={`border-b ${theme.border}`}
              href={CLIENT_ARTIST_COLLECTORS_URL}
              copy={'MY COLLECTORS'}
            />
            <NavMenuLink
              border={`border-b ${theme.border}`}
              href={CLIENT_ARTIST_WAITLIST_PAGE_URL}
              copy={'MY WAITLIST'}
            />
            <NavMenuLink
              border={`border-b ${theme.border}`}
              href={CLIENT_USER_ACCOUNT_URL}
              copy={'ACCOUNT DETAILS'}
            />
          </>
        )}
        {gallery && (
          <>
            <ProfileIdentifier>GALLERY PROFILE</ProfileIdentifier>
            <NavMenuLink
              border={`border-b ${theme.border}`}
              href={CLIENT_GALERY_WAITLIST_PAGE_URL}
              copy={'MY WAITLIST'}
            />
            <NavMenuLink
              border={`border-b ${theme.border}`}
              href={CLIENT_USER_ACCOUNT_URL}
              copy={'ACCOUNT DETAILS'}
            />
          </>
        )}
        {user && (
          <button
            onClick={logout}
            className={`flex items-center justify-between font-medium text-s py-1.6 text-opacity-50 ${theme.color}`}
          >
            LOG OUT
          </button>
        )}
      </div>
    </div>
  );
}
