import { TextCtaLink } from '@/components/CtaLink';
import { useSearchSortAndFilterProviderV2 } from '@/components/Provider/SearchSortAndFilterProviderV2';

export default function NoResultsFound({
  ctaLink,
  ctaCopy,
}: {
  ctaLink?: string;
  ctaCopy?: string;
}) {
  const { searchText } = useSearchSortAndFilterProviderV2();
  return (
    <>
      <div className="col-span-12 mt-48 text-center copy-l text-black-300 mb-1.6">
        NO RESULTS FOUND FOR &ldquo;{searchText}&rdquo;
      </div>
      <div className="col-span-12 text-center text-body text-black-300">
        Try checking for spelling or try another word.
        {ctaLink && ctaCopy && (
          <>
            {' '}
            or{' '}
            <TextCtaLink display="inline" color="text-black-100" href={ctaLink}>
              {ctaCopy}
            </TextCtaLink>
          </>
        )}
      </div>
    </>
  );
}

export function NoResultsFoundNonSearch() {
  return (
    <>
      <div className="col-span-12 mt-48 text-center copy-l text-black-300 mb-1.6">
        NO RESULTS FOUND...
      </div>
    </>
  );
}
