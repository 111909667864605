import { createContext, Dispatch, SetStateAction } from 'react';

type NavBarContextProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  theme: NavBarThemeStyles;
  sticky: boolean;
  isSearchOpen: boolean;
  setIsSearchOpen: Dispatch<SetStateAction<boolean>>;
  scrolling: boolean;
  setScrolling: Dispatch<SetStateAction<boolean>>;
};

export type NavBarThemeStyles = {
  color: string;
  textHover: string;
  transition: string;
  background: string;
  backgroundOpacity: string;
  border: string;
  filter: string;
  shadow: string;
  sticky: string;
};

export const NavBarContext = createContext<NavBarContextProps | null>(null);
