import useUserV2 from '@/hooks/queries/auth_user/useUserV2';
import {
  CLIENT_ABOUT_URL,
  CLIENT_ADMIN_WAITLIST_VIEW_URL,
  CLIENT_ARTIST_COLLECTORS_URL,
  CLIENT_ARTIST_WAITLIST_PAGE_URL,
  CLIENT_DISCOVER_URL,
  CLIENT_GALERY_WAITLIST_PAGE_URL,
  CLIENT_OLEA_COLLECTION_URL,
  CLIENT_USER_COLLECTION_URL,
} from '@/utils/constants';
import {
  isAdmin,
  isArtist,
  isAuthenticated,
  isGallery,
} from '@/utils/v2HelperMethods';
import Link from 'next/link';
import React from 'react';
import { useNavBarTheme } from './NavBarProvider';

function NavBarLink({ href, copy }: { href: string; copy: string }) {
  const theme = useNavBarTheme();
  return (
    (<Link
      href={href}
      className={`mr-2.4 flex items-center justify-between font-medium text-s ${theme.color} ${theme.textHover} ${theme.transition}`}>

      {copy}

    </Link>)
  );
}

export default function NavBarDesktopLinks() {
  const { data: user, isLoading } = useUserV2();

  const artist = !isLoading && isArtist(user);
  const gallery = !isLoading && isGallery(user);
  const auth = !isLoading && isAuthenticated(user);

  return (
    <div className="flex ml-36">
      {artist && (
        <>
          <NavBarLink
            href={CLIENT_ARTIST_COLLECTORS_URL}
            copy={'MY COLLECTORS'}
          />
          <NavBarLink
            href={CLIENT_ARTIST_WAITLIST_PAGE_URL}
            copy={'MY WAITLIST'}
          />
          <NavBarLink href={CLIENT_ABOUT_URL} copy={'ABOUT'} />
        </>
      )}
      {gallery && (
        <>
          <NavBarLink
            href={CLIENT_GALERY_WAITLIST_PAGE_URL}
            copy={'MY WAITLIST'}
          />
          <NavBarLink href={CLIENT_ABOUT_URL} copy={'ABOUT'} />
        </>
      )}
      {!gallery && !artist && (
        <>
          <NavBarLink href={CLIENT_DISCOVER_URL} copy={'DISCOVER'} />
          <NavBarLink
            href={CLIENT_USER_COLLECTION_URL}
            copy={'COLLECTION MANAGEMENT'}
          />
          <NavBarLink href={CLIENT_ABOUT_URL} copy={'ABOUT'} />
          {auth && (
            <NavBarLink
              href={CLIENT_OLEA_COLLECTION_URL}
              copy={'OLEA COLLECTION'}
            />
          )}
        </>
      )}
    </div>
  );
}
