import { UserAndJwt } from '@/lib/interfaces';
import { BACKEND_API, genJwtToken } from '@/lib/utils/v2HttpRequests';
import { LOGOUT } from '@/utils/localStorageKeys';
import axios, { AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import router from 'next/router';

const preventServerExecution = () => {
  //prevent function from being ran on the server
  if (typeof window === 'undefined') {
    throw new Error('Window is Undefined');
  }
};

//register a new user
export const registerUser = async (params: any): Promise<UserAndJwt> => {
  preventServerExecution();

  const url = BACKEND_API + '/api/auth/local/register';

  const response = await axios.post(url, { ...params, username: params.email });

  Cookies.set('token', response.data.jwt);
  return response.data;
};

export const login = async ({
  identifier,
  password,
}: {
  identifier: string;
  password: string;
}): Promise<UserAndJwt> => {
  preventServerExecution();

  const url = BACKEND_API + '/api/auth/local';

  const response: AxiosResponse<UserAndJwt> = await axios.post(url, {
    identifier,
    password,
  });

  Cookies.set('token', response.data.jwt);

  return response.data;
};

export const resetPassword = async ({
  code,
  password,
  passwordConfirmation,
}: {
  code: string;
  password: string;
  passwordConfirmation: string;
}): Promise<AxiosResponse<UserAndJwt>> => {
  preventServerExecution();

  const url = BACKEND_API + '/api/auth/reset-password';

  const response = await axios.post(url, {
    code,
    password,
    passwordConfirmation,
  });

  Cookies.set('token', response.data.jwt);
  return response;
};

export const resetPasswordRequest = async ({ email }: { email: string }) => {
  preventServerExecution();

  const url = BACKEND_API + '/api/auth/forgot-password';

  const response = await axios.post(url, { email });

  return response;
};

export const changePasswordRequest = async ({
  currentPassword,
  password,
  passwordConfirmation,
}: {
  currentPassword: string;
  password: string;
  passwordConfirmation: string;
}) => {
  preventServerExecution();

  const url = BACKEND_API + '/api/auth/change-password';

  const response = await axios.post(
    url,
    {
      currentPassword,
      password,
      passwordConfirmation,
    },
    genJwtToken()
  );

  Cookies.set('token', response.data.jwt);

  return response;
};

export const logout = () => {
  //remove token and user cookie
  Cookies.remove('token');
  delete (window as any).__user;
  // sync logout between multiple windows
  window.localStorage.setItem(LOGOUT, `${Date.now()}`);
  router.reload();
};
