import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';

type TabProviderContextProps = {
  tab: number;
  setTab: Dispatch<SetStateAction<number>>;
};

const TabProviderContext = createContext<TabProviderContextProps | null>(null);

export function useTabProvider() {
  const ctx = useContext(TabProviderContext);
  if (!ctx) throw Error('Need to Set Context Value');
  return ctx;
}

export default function TabProvider({
  initialTab,
  children,
}: Readonly<{
  initialTab: number;
  children: ReactNode;
}>) {
  const [tab, setTab] = useState<number>(initialTab);

  const tabsMemoValues = useMemo(
    () => ({
      tab,
      setTab,
    }),
    [tab]
  );

  return (
    <TabProviderContext.Provider value={tabsMemoValues}>
      {children}
    </TabProviderContext.Provider>
  );
}
