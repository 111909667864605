export default function UserAvatar({ large = false }: { large?: boolean }) {
  return large ? (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-current"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 6V8C14 9.10457 13.1046 10 12 10C10.8954 10 10 9.10457 10 8V6C10 4.89543 10.8954 4 12 4C13.1046 4 14 4.89543 14 6ZM8 6C8 3.79086 9.79086 2 12 2C14.2091 2 16 3.79086 16 6V8C16 10.2091 14.2091 12 12 12C9.79086 12 8 10.2091 8 8V6ZM10 13C6.13401 13 3 16.134 3 20C3 21.1046 3.89543 22 5 22L19 22C20.1046 22 21 21.1046 21 20C21 16.134 17.866 13 14 13L10 13ZM5 20C5 17.2386 7.23858 15 10 15L14 15C16.7614 15 19 17.2386 19 20L5 20Z"
      />
    </svg>
  ) : (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-current"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 6C11 7.65685 9.65681 9 7.99995 9C6.3431 9 4.99995 7.65685 4.99995 6C4.99995 4.34315 6.3431 3 7.99995 3C9.65681 3 11 4.34315 11 6ZM9.99995 6C9.99995 7.10457 9.10452 8 7.99995 8C6.89538 8 5.99995 7.10457 5.99995 6C5.99995 4.89543 6.89538 4 7.99995 4C9.10452 4 9.99995 4.89543 9.99995 6Z"
      />
      <path d="M4.58005 10.8923C3.51427 11.489 2.62319 12.3543 1.99536 13.4021L2.81199 13.8914C3.35443 12.9861 4.12432 12.2385 5.04516 11.7229C5.966 11.2073 7.00573 10.9416 8.06104 10.9523C9.11635 10.963 10.1505 11.2496 11.0607 11.7837C11.971 12.3178 12.7256 13.0808 13.2497 13.9968L14.076 13.5241C13.4695 12.4639 12.596 11.5808 11.5425 10.9626C10.489 10.3444 9.29208 10.0127 8.07065 10.0004C6.84923 9.98802 5.64584 10.2955 4.58005 10.8923Z" />
    </svg>
  );
}
