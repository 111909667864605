import RightCheveron from '@/components/Icons/RightCheveron';
import Link from 'next/link';
import { ReactNode } from 'react';

export const NavMenuLink = ({
  href,
  copy,
  border = 'border-b border-black border-opacity-10',
  icon = <RightCheveron stroke="currentColor" />,
}: {
  href: string;
  copy: string;
  border?: string;
  icon?: ReactNode;
}) => (
  (<Link
    href={href}
    className={`flex items-center justify-between font-medium text-s py-1.6 ${border}`}>

    {copy}
    {icon}

  </Link>)
);
